import React, { Suspense } from 'react';
import { Navigate, Outlet, useRoutes } from 'react-router-dom';
import { ROUTES } from '@/shared/constants/routes';
import { privateRoutes, publicRoutes } from '@/shared/config/routesConfig';
import LoadingSpinner from '@/shared/components/LoadingSpinner/LoadingSpinner';
import { QUERY_MESSAGES } from '@/shared/constants/loadingMessages';

import * as Sentry from '@sentry/react';
import useAuthState from '@/modules/accounts/hooks/UseAuthState';

// Оборачиваем useRoutes с помощью Sentry
const useSentryRoutes = Sentry.wrapUseRoutes(useRoutes);

// Приватный маршрут, доступен только если есть токен
const PrivateRoute: React.FC = () => {
	const { isAuthenticated } = useAuthState();
	// Если есть токен — рендерю дочерние маршруты, иначе — перенаправляю на логин
	return isAuthenticated ? <Outlet /> : <Navigate to={ROUTES.LOGIN} replace />;
};

// Все маршруты приложения
const AppRoutes: React.FC = () => {
	return useSentryRoutes([
		...publicRoutes,
		{
			path: '/',
			// Приватные маршруты
			element: <PrivateRoute />,
			children: privateRoutes,
		},
	]);
};

// Оборачиваю в Suspense для ленивой загрузки
const RoutesWrapper: React.FC = () => (
	<Suspense fallback={<LoadingSpinner message={QUERY_MESSAGES.loadingApp.loadingMessage} />}>
		<AppRoutes />
	</Suspense>
);

export default React.memo(RoutesWrapper);
