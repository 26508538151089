import { API_ENDPOINTS } from '@/shared/constants/apiEndpoints';
import { baseQueryWithReauth } from '@/shared/utils/apiBase';
import { createApi } from '@reduxjs/toolkit/query/react';
import { Trigger, TriggerFilters } from '@/modules/accounts/types';
import { buildQueryParams } from '@/shared/utils/buildQueryParams';
import selectFields from '@/shared/utils/selectFields';
import processField from '@/shared/utils/processField';

export const triggersApi = createApi({
	reducerPath: 'triggersApi',
	baseQuery: baseQueryWithReauth,
	tagTypes: ['Triggers'],
	endpoints: (builder) => ({
		// Получение метаинформации для страницы триггеров
		getTriggersMeta: builder.query<{ statuses: string[]; columns: string[] }, void>({
			query: () => API_ENDPOINTS.TRIGGER.META,
		}),

		// Получение списка триггеров для аккаунта
		getTriggers: builder.query<
			{ triggers: Trigger[]; total: number; page: number; limit: number },
			Partial<TriggerFilters>
		>({
			query: (filters) => {
				const { account_id } = filters;

				// Проверка на наличие account_id
				if (!account_id) {
					throw new Error('account_id is required to fetch triggers');
				}

				const filterRules = {
					name: (filters: Partial<TriggerFilters>, params: URLSearchParams) => {
						if (filters.name) params.append('name', filters.name);
					},
					search_string: (filters: Partial<TriggerFilters>, params: URLSearchParams) => {
						if (filters.search_string)
							params.append('search_string', filters.search_string);
					},
					status: (filters: Partial<TriggerFilters>, params: URLSearchParams) => {
						if (filters.status) params.append('status', filters.status.toString());
					},
					page: (filters: Partial<TriggerFilters>, params: URLSearchParams) => {
						if (filters.page) params.append('page', filters.page.toString());
					},
					limit: (filters: Partial<TriggerFilters>, params: URLSearchParams) => {
						if (filters.limit) params.append('limit', filters.limit.toString());
					},
					sort: (filters: Partial<TriggerFilters>, params: URLSearchParams) => {
						if (filters.sortKey && filters.sortOrder) {
							const sortParam =
								filters.sortOrder === 'descend'
									? `-${filters.sortKey}`
									: filters.sortKey;
							params.append('sort', String(sortParam));
						}
					},
				};

				// Генерация параметров запроса
				const queryParams = buildQueryParams(filters, filterRules);

				// Формирование конечного URL
				const url = `${API_ENDPOINTS.TRIGGER.LIST(account_id)}${queryParams}`;
				return { url };
			},
			providesTags: (result) =>
				result?.triggers
					? [
							...result.triggers.map(({ id }: Trigger) => ({
								type: 'Triggers' as const,
								id,
							})),
							{ type: 'Triggers', id: 'LIST' },
						]
					: [{ type: 'Triggers', id: 'LIST' }],
		}),

		// Получение данных триггера по ID
		getTriggerById: builder.query<Trigger, { account_id: string; id: string }>({
			query: (queryParams) => {
				const { account_id, id: trigger_id } = queryParams;

				if (!account_id || !trigger_id) {
					throw new Error('Invalid query parameters');
				}

				// Формируем запрос с осмысленными именами переменных
				return API_ENDPOINTS.TRIGGER.BY_ID(Number(account_id), Number(trigger_id));
			},
		}),

		// Создание нового триггера
		createTrigger: builder.mutation<
			Trigger,
			{ account_id: string | number; body: Partial<Trigger> }
		>({
			query: (body) => {
				// Перечисляем нужные поля, которые хотим отправить
				const includeFields = ['account_id', 'name', 'status', 'settings'];

				// Используем утилиту для выбора нужных полей
				const selectedBody = selectFields(body, includeFields);

				const { account_id, ...serializedBody } = selectedBody;

				if (serializedBody.settings) {
					serializedBody.settings = processField(serializedBody.settings);
				}

				// Возвращаем корректный запрос
				return {
					url: API_ENDPOINTS.TRIGGER.CREATE(account_id),
					method: 'POST',
					body: serializedBody,
				};
			},
			invalidatesTags: [{ type: 'Triggers', id: 'LIST' }],
		}),

		// Обновление данных триггера
		updateTrigger: builder.mutation<
			Trigger,
			{ account_id: string | number; id: string | number; body: Partial<Trigger> }
		>({
			query: (body) => {
				// Перечисляем нужные поля, которые хотим отправить
				const includeFields = ['account_id', 'name', 'status', 'settings', 'id'];

				// Используем утилиту для выбора нужных полей
				const selectedBody = selectFields(body, includeFields);

				const { account_id, id, ...serializedBody } = selectedBody;

				// Обрабатываем поля body и settings
				if (serializedBody.body) {
					serializedBody.body = processField(serializedBody.body);
				}

				if (serializedBody.settings) {
					serializedBody.settings = processField(serializedBody.settings);
				}

				// Возвращаем корректный запрос
				return {
					url: API_ENDPOINTS.TRIGGER.UPDATE(account_id, id),
					method: 'PUT',
					body: serializedBody,
				};
			},
			invalidatesTags: (_result, _error, { id }) => [
				{ type: 'Triggers', id: id },
				{ type: 'Triggers', id: 'LIST' },
			],
		}),

		// Удаление триггера
		deleteTrigger: builder.mutation<void, { account_id: string | number; id: string | number }>(
			{
				query: ({ account_id, id }) => {
					// Формируем запрос для удаления триггера
					return {
						url: API_ENDPOINTS.TRIGGER.DELETE(account_id, id),
						method: 'DELETE',
					};
				},
				invalidatesTags: (_result, _error, { id }) => [
					{ type: 'Triggers', id: id },
					{ type: 'Triggers', id: 'LIST' },
				],
			},
		),
	}),
});

export const {
	useGetTriggersMetaQuery,
	useGetTriggersQuery,
	useGetTriggerByIdQuery,
	useCreateTriggerMutation,
	useUpdateTriggerMutation,
	useDeleteTriggerMutation,
} = triggersApi;
