// Утилита для выборки нужных полей
const selectFields = <T extends Record<string, any>>(
	obj: T,
	includeFields: string[],
): Record<string, any> => {
	const selectedObj: Record<string, any> = {}; // Можно использовать Record для динамических ключей

	includeFields.forEach((field) => {
		if (field in obj) {
			selectedObj[field] = obj[field];
		}
	});

	return selectedObj;
};

export default selectFields;
