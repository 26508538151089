import { baseQueryWithReauth } from '@/shared/utils/apiBase';
import { createApi } from '@reduxjs/toolkit/query/react';
import { Filters, User } from '@/modules/users/types';
import { API_ENDPOINTS } from '@/shared/constants/apiEndpoints';
import { buildQueryParams } from '@/shared/utils/buildQueryParams';
import selectFields from '@/shared/utils/selectFields';

// API для работы с пользователями
export const usersApi = createApi({
	reducerPath: 'usersApi',
	baseQuery: baseQueryWithReauth,
	tagTypes: ['Users'],
	endpoints: (builder) => ({
		// Получение метаинформации для страницы пользователей
		getUsersMeta: builder.query<
			{ roles: string[]; statuses: string[]; columns: string[] },
			void
		>({
			query: () => API_ENDPOINTS.USER.META,
		}),

		// Получение списка пользователей с фильтрацией, сортировкой и пагинацией
		getUsers: builder.query<
			{ users: User[]; total: number; page: number; limit: number },
			Partial<Filters>
		>({
			query: (filters) => {
				const filterRules = {
					id: (filters: Partial<Filters>, params: URLSearchParams) =>
						filters.id && params.append('id', filters.id.toString()),
					email: (filters: Partial<Filters>, params: URLSearchParams) =>
						filters.email && params.append('email', filters.email),
					role: (filters: Partial<Filters>, params: URLSearchParams) =>
						filters.role && params.append('role', filters.role),
					status: (filters: Partial<Filters>, params: URLSearchParams) =>
						filters.status && params.append('status', filters.status.toString()),
					startDate: (filters: Partial<Filters>, params: URLSearchParams) =>
						filters.startDate && params.append('startDate', filters.startDate),
					endDate: (filters: Partial<Filters>, params: URLSearchParams) =>
						filters.endDate && params.append('endDate', filters.endDate),
					page: (filters: Partial<Filters>, params: URLSearchParams) =>
						filters.page && params.append('page', filters.page.toString()),
					limit: (filters: Partial<Filters>, params: URLSearchParams) =>
						filters.limit && params.append('limit', filters.limit.toString()),
					sort: (filters: Partial<Filters>, params: URLSearchParams) =>
						filters.sortKey &&
						filters.sortOrder &&
						params.append(
							'sort',
							filters.sortOrder === 'descend'
								? `-${filters.sortKey}`
								: filters.sortKey,
						),
				};

				const queryParams = buildQueryParams(filters, filterRules);
				return {
					url: `${API_ENDPOINTS.USER.LIST}${queryParams}`,
				};
			},
			providesTags: (result) =>
				result?.users
					? [
							...result.users.map(({ id }: User) => ({
								type: 'Users' as const,
								id,
							})),
							{ type: 'Users', id: 'LIST' },
						]
					: [{ type: 'Users', id: 'LIST' }],
		}),

		// Получение данных пользователя по ID
		getUserById: builder.query<User, { id: number }>({
			query: ({ id }) => API_ENDPOINTS.USER.BY_ID(id),
		}),

		// Создание пользователя
		createUser: builder.mutation<User, Omit<Partial<User>, 'action' | 'id'>>({
			query: (body) => {
				const includeFields = ['email', 'password', 'role', 'status'];
				const selectedBody = selectFields(body, includeFields);

				return {
					url: API_ENDPOINTS.USER.CREATE,
					method: 'POST',
					body: selectedBody,
				};
			},
			invalidatesTags: [{ type: 'Users', id: 'LIST' }],
		}),

		// Обновление пользователя
		updateUser: builder.mutation<User, { id: number; role: number; status: number }>({
			query: (body) => {
				const { id, role, status } = body;

				const filteredBody = {
					role,
					status,
				};

				return {
					url: API_ENDPOINTS.USER.UPDATE(id),
					method: 'PUT',
					body: filteredBody,
				};
			},
			invalidatesTags: (_result, _error, { id }) => [{ type: 'Users', id }],
		}),

		// Мягкое удаление пользователя
		softDeleteUser: builder.mutation<void, { id: number }>({
			query: ({ id }) => ({
				url: API_ENDPOINTS.USER.DELETE(id),
				method: 'DELETE',
			}),
			invalidatesTags: (_result, _error, { id }) => [{ type: 'Users', id }],
		}),

		// Смена пароля пользователя
		changePassword: builder.mutation<void, { id: number; password: string }>({
			query: ({ id, password }) => ({
				url: API_ENDPOINTS.USER.CHANGE_PASSWORD(id),
				method: 'POST',
				body: { password },
			}),
			invalidatesTags: [{ type: 'Users', id: 'LIST' }],
		}),
	}),
});

export const {
	useGetUsersMetaQuery,
	useGetUsersQuery,
	useGetUserByIdQuery,
	useCreateUserMutation,
	useUpdateUserMutation,
	useSoftDeleteUserMutation,
	useChangePasswordMutation,
} = usersApi;
