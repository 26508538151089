import { BaseQueryFn, FetchArgs, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ENV } from '@/config/env';
import { getLocalStorageToken, setLocalStorageToken } from '@/shared/utils/localStorage';
import { logout, setToken } from '@/modules/auth/store/authSlice';
import { API_ENDPOINTS } from '@/shared/constants/apiEndpoints';
import { authApi } from '@/modules/auth/api/authApi';
import { AppDispatch } from '@/app/store/store';
import { startLoading } from '@/app/store/loadingSlice';
import { QUERY_MESSAGES } from '@/shared/constants/loadingMessages';
import { HTTP_STATUS_CODES } from '@/shared/constants/httpStatusCodes';
import { ROUTES } from '@/shared/constants/routes';

const baseQuery = fetchBaseQuery({
	baseUrl: ENV.API_BASE_URL,
	prepareHeaders: (headers) => {
		const token = getLocalStorageToken();
		if (token) {
			headers.set('Authorization', `Bearer ${token}`);
		}
		headers.set('Origin', window.location.origin);
		return headers;
	},
	credentials: 'include',
});

function isRefreshTokenRequest(args: string | FetchArgs): boolean {
	return (
		typeof args === 'object' && 'url' in args && args.url === API_ENDPOINTS.AUTH.REFRESH_TOKEN
	);
}

// Список URL для которых не делаем refresh token при 401
const NO_REFRESH_URLS = [
	API_ENDPOINTS.AUTH.LOGIN,
	API_ENDPOINTS.AUTH.RESET_PASSWORD_REQUEST,
	API_ENDPOINTS.AUTH.RESET_PASSWORD,
	API_ENDPOINTS.AUTH.LOGOUT,
];

// Функция для проверки, нужно ли пропускать refresh token для данного URL
function shouldSkipRefresh(args: string | FetchArgs): boolean {
	if (typeof args === 'object' && 'url' in args) {
		// Проверяем, начинается ли URL с одного из путей, указанных в NO_REFRESH_URLS
		return NO_REFRESH_URLS.some((url) => args.url?.startsWith(url));
	}
	return false;
}

export const baseQueryWithReauth: BaseQueryFn<string | FetchArgs> = async (
	args,
	api,
	extraOptions,
) => {
	let result = await baseQuery(args, api, extraOptions);

	const dispatch = api.dispatch as AppDispatch;

	if (result.error) {
		if (result.error?.status === HTTP_STATUS_CODES.UNAUTHORIZED) {
			// Если получаем 401 и запрос в списке `NO_REFRESH_URLS`, просто возвращаем результат без обновления токена
			if (shouldSkipRefresh(args)) {
				return result;
			}

			// Если это запрос на обновление токена и он вернул 401, разлогиниваем пользователя
			if (isRefreshTokenRequest(args)) {
				dispatch(logout());
				return result;
			}

			// Пытаемся обновить токен через мутацию, если это не запрос из списка `NO_REFRESH_URLS`
			const refreshResult = await dispatch(authApi.endpoints.refreshToken.initiate());

			if (refreshResult.data) {
				// Обновляем токен в localStorage и Redux
				const { token: newToken } = refreshResult.data as { token: string };
				setLocalStorageToken(newToken);
				dispatch(setToken(newToken));

				// Проверяем, есть ли endpoint в `QUERY_MESSAGES`
				const endpoint = api.endpoint as keyof typeof QUERY_MESSAGES;
				if (QUERY_MESSAGES[endpoint]) {
					const { loadingMessage } = QUERY_MESSAGES[endpoint];
					dispatch(startLoading({ loading: true, loadingMessage }));
				}

				// Повторяем исходный запрос с новым токеном
				result = await baseQuery(args, api, extraOptions);

				if (result.error) {
					// dispatch(logout());
				}
			} else {
				// Если обновление токена не удалось, разлогиниваем пользователя
				dispatch(logout());
				window.location.replace(ROUTES.LOGIN);
			}
		}
		// Если ошибка 403, не пытаемся обновить токен
		if (result.error?.status === HTTP_STATUS_CODES.FORBIDDEN) {
			// Просто возвращаем ошибку 403 без попытки обновить токен
			return result;
		}
	}

	return result;
};
