import { API_ENDPOINTS } from '@/shared/constants/apiEndpoints';
import { baseQueryWithReauth } from '@/shared/utils/apiBase';
import { createApi } from '@reduxjs/toolkit/query/react';
import { Integration, IntegrationFilters } from '@/modules/accounts/types';
import { buildQueryParams } from '@/shared/utils/buildQueryParams';
import selectFields from '@/shared/utils/selectFields';
import processField from '@/shared/utils/processField';

export const integrationsApi = createApi({
	reducerPath: 'integrationsApi',
	baseQuery: baseQueryWithReauth,
	tagTypes: ['Integrations'],
	endpoints: (builder) => ({
		// Получение метаинформации для страницы интеграций
		getIntegrationsMeta: builder.query<{ statuses: string[]; columns: string[] }, void>({
			query: () => API_ENDPOINTS.INTEGRATION.META,
		}),

		// Получение списка интеграций для аккаунта
		getIntegrations: builder.query<
			{ integrations: Integration[]; total: number; page: number; limit: number },
			Partial<IntegrationFilters>
		>({
			query: (filters) => {
				const { account_id } = filters;

				// Проверка на наличие account_id
				if (!account_id) {
					throw new Error('account_id is required to fetch integrations');
				}

				const filterRules = {
					name: (filters: Partial<IntegrationFilters>, params: URLSearchParams) => {
						if (filters.name) params.append('name', filters.name);
					},
					search_string: (
						filters: Partial<IntegrationFilters>,
						params: URLSearchParams,
					) => {
						if (filters.search_string)
							params.append('search_string', filters.search_string);
					},
					type: (filters: Partial<IntegrationFilters>, params: URLSearchParams) => {
						if (filters.type !== undefined)
							params.append('type', filters.type.toString());
					},
					status: (filters: Partial<IntegrationFilters>, params: URLSearchParams) => {
						if (filters.status !== undefined)
							params.append('status', filters.status.toString());
					},
					page: (filters: Partial<IntegrationFilters>, params: URLSearchParams) => {
						if (filters.page) params.append('page', filters.page.toString());
					},
					limit: (filters: Partial<IntegrationFilters>, params: URLSearchParams) => {
						if (filters.limit) params.append('limit', filters.limit.toString());
					},
					sort: (filters: Partial<IntegrationFilters>, params: URLSearchParams) => {
						if (filters.sortKey && filters.sortOrder) {
							const sortParam =
								filters.sortOrder === 'descend'
									? `-${filters.sortKey}`
									: filters.sortKey;
							params.append('sort', String(sortParam));
						}
					},
				};

				// Генерация параметров запроса
				const queryParams = buildQueryParams(filters, filterRules);

				// Формирование конечного URL
				const url = `${API_ENDPOINTS.INTEGRATION.LIST(account_id)}${queryParams}`;
				return { url };
			},
			providesTags: (result) =>
				result?.integrations
					? [
							...result.integrations.map(({ id }: Integration) => ({
								type: 'Integrations' as const,
								id,
							})),
							{ type: 'Integrations', id: 'LIST' },
						]
					: [{ type: 'Integrations', id: 'LIST' }],
		}),

		// Получение данных интеграции по ID
		getIntegrationById: builder.query<Integration, { account_id: string; id: string }>({
			query: (queryParams) => {
				const { account_id, id: integration_id } = queryParams;

				if (!account_id || !integration_id) {
					throw new Error('Invalid query parameters');
				}

				// Формируем запрос с осмысленными именами переменных
				return API_ENDPOINTS.INTEGRATION.BY_ID(Number(account_id), Number(integration_id));
			},
		}),

		// Создание интеграции
		createIntegration: builder.mutation<
			Integration,
			{ account_id: number; body: Partial<Integration> }
		>({
			query: (body) => {
				// Перечисляем нужные поля, которые хотим отправить
				const includeFields = [
					'account_id',
					'name',
					'credentials',
					'settings',
					'status',
					'type',
				];

				// Используем утилиту для выбора нужных полей
				const selectedBody = selectFields(body, includeFields);

				const { account_id, ...serializedBody } = selectedBody;

				// Обрабатываем поля credentials и settings
				if (serializedBody.credentials) {
					serializedBody.credentials = processField(serializedBody.credentials);
				}

				if (serializedBody.settings) {
					serializedBody.settings = processField(serializedBody.settings);
				}

				// Возвращаем корректный запрос
				return {
					url: API_ENDPOINTS.INTEGRATION.CREATE(account_id),
					method: 'POST',
					body: serializedBody,
				};
			},
			invalidatesTags: [{ type: 'Integrations', id: 'LIST' }],
		}),

		// Обновление интеграции
		updateIntegration: builder.mutation<
			Integration,
			{ account_id: number; id: number; body: Partial<Integration> }
		>({
			query: (body) => {
				// Перечисляем нужные поля, которые хотим отправить
				const includeFields = [
					'account_id',
					'name',
					'credentials',
					'settings',
					'status',
					'type',
					'id',
				];

				// Используем утилиту для выбора нужных полей
				const selectedBody = selectFields(body, includeFields);

				const { account_id, id, ...serializedBody } = selectedBody;

				// Обрабатываем поля credentials и settings
				if (serializedBody.credentials) {
					serializedBody.credentials = processField(serializedBody.credentials);
				}

				if (serializedBody.settings) {
					serializedBody.settings = processField(serializedBody.settings);
				}

				// Возвращаем корректный запрос
				return {
					url: API_ENDPOINTS.INTEGRATION.UPDATE(account_id, id),
					method: 'PUT',
					body: serializedBody,
				};
			},
			invalidatesTags: (_result, _error, { id }) => [
				{ type: 'Integrations', id: id },
				{ type: 'Integrations', id: 'LIST' },
			],
		}),

		// Удаление интеграции
		deleteIntegration: builder.mutation<void, { account_id: number; id: number }>({
			query: (body) => {
				const { account_id, id } = body;

				return {
					url: API_ENDPOINTS.INTEGRATION.DELETE(account_id, id),
					method: 'DELETE',
				};
			},
			invalidatesTags: (_result, _error, { id }) => [
				{ type: 'Integrations', id: id },
				{ type: 'Integrations', id: 'LIST' },
			],
		}),
	}),
});

export const {
	useGetIntegrationsQuery,
	useGetIntegrationsMetaQuery,
	useGetIntegrationByIdQuery,
	useCreateIntegrationMutation,
	useUpdateIntegrationMutation,
	useDeleteIntegrationMutation,
} = integrationsApi;
