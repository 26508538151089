import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import store from './store/store';
import useAuthInitialization from '@/modules/auth/hooks/useAuthInitialization';
import LoadingSpinner from '@/shared/components/LoadingSpinner/LoadingSpinner';
import RoutesWrapper from '@/app/routes';

const AppContent: React.FC = () => {
	// Проверяю состояние загрузки при инициализации
	const { loading, loadingMessage } = useAuthInitialization();

	return (
		<>
			{/* Основной контент приложения */}
			<RoutesWrapper />

			{/* Спиннер отображается поверх основного контента при загрузке */}
			{loading && <LoadingSpinner message={loadingMessage} />}
		</>
	);
};

// Основной компонент приложения
const App: React.FC = () => (
	<Provider store={store}>
		<Router>
			<AppContent />
		</Router>
	</Provider>
);

export default App;
