import { API_ENDPOINTS } from '@/shared/constants/apiEndpoints';
import { baseQueryWithReauth } from '@/shared/utils/apiBase';
import { createApi } from '@reduxjs/toolkit/query/react';
import { Project, ProjectFilters } from '@/modules/accounts/types';
import { buildQueryParams } from '@/shared/utils/buildQueryParams';
import selectFields from '@/shared/utils/selectFields';
import processField from '@/shared/utils/processField';
// Пример типов для интеграций
interface IntegrationRequest {
	integration_id: number;
	settings?: any;
}

interface IntegrationResponse {
	id: number;
	created_at: string;
	updated_at: string;
	status: number;
	integration_id: number;
	integration_type_id: number;
	integration_role: number;
	trigger_id: number | null;
	settings: any;
}

// Пример типов для промптов
interface AiPromptRequest {
	ai_prompt_id: number;
	settings?: any;
}

interface AiPromptResponse {
	id: number;
	ai_prompt_id: number;
	settings: any;
}

export const projectsApi = createApi({
	reducerPath: 'projectsApi',
	baseQuery: baseQueryWithReauth,
	tagTypes: ['Projects', 'ProjectIntegrations', 'ProjectAiPrompts'],
	endpoints: (builder) => ({
		// Получение метаинформации для страницы проектов
		getProjectsMeta: builder.query<{ statuses: string[]; columns: string[] }, void>({
			query: () => API_ENDPOINTS.PROJECT.META,
		}),

		// Получение списка проектов для аккаунта
		getProjects: builder.query<
			{ projects: Project[]; total: number; page: number; limit: number },
			{ account_id: string | number; limit?: number; page?: number }
		>({
			query: (filters) => {
				const { account_id } = filters;
				const filterRules = {
					name: (filters: Partial<ProjectFilters>, params: URLSearchParams) => {
						if (filters.name) params.append('name', filters.name);
					},
					status: (filters: Partial<ProjectFilters>, params: URLSearchParams) => {
						if (filters.status !== undefined)
							params.append('status', filters.status.toString());
					},
					page: (filters: Partial<ProjectFilters>, params: URLSearchParams) => {
						if (filters.page) params.append('page', filters.page.toString());
					},
					limit: (filters: Partial<ProjectFilters>, params: URLSearchParams) => {
						if (filters.limit) params.append('limit', filters.limit.toString());
					},
					sort: (filters: Partial<ProjectFilters>, params: URLSearchParams) => {
						if (filters.sortKey && filters.sortOrder) {
							const sortParam =
								filters.sortOrder === 'descend'
									? `-${filters.sortKey}`
									: filters.sortKey;
							params.append('sort', String(sortParam));
						}
					},
				};

				// Генерация параметров запроса
				const queryParams = buildQueryParams(filters, filterRules);

				// Формирование конечного URL

				const url = `${API_ENDPOINTS.PROJECT.LIST(account_id)}${queryParams}`;
				return { url };
			},
			providesTags: (result) =>
				result?.projects
					? [
							...result.projects.map(({ id }: Project) => ({
								type: 'Projects' as const,
								id,
							})),
							{ type: 'Projects', id: 'LIST' },
						]
					: [{ type: 'Projects', id: 'LIST' }],
		}),

		// Получение данных проекта по ID
		getProjectById: builder.query<Project, { account_id: string; id: string }>({
			query: (queryParams) => {
				const { account_id, id: project_id } = queryParams;

				if (!account_id || !project_id) {
					throw new Error('Invalid query parameters');
				}

				// Формируем запрос с осмысленными именами переменных
				return API_ENDPOINTS.PROJECT.BY_ID(Number(account_id), Number(project_id));
			},
		}),

		// Создание проекта
		createProject: builder.mutation<
			Project,
			{ account_id: string | number; body: Partial<Project> }
		>({
			query: (body) => {
				// Перечисляем нужные поля, которые хотим отправить
				const includeFields = [
					'account_id',
					'name',
					'status',
					// 'connected_ai_prompts',
					// 'connected_integrations',
				];

				// Используем утилиту для выбора нужных полей
				const selectedBody = selectFields(body, includeFields);

				const { account_id, ...serializedBody } = selectedBody;

				if (serializedBody.connected_ai_prompts) {
					serializedBody.connected_ai_prompts = processField(
						serializedBody.connected_ai_prompts,
					);
				}

				if (serializedBody.connected_integrations) {
					serializedBody.connected_integrations = processField(
						serializedBody.connected_integrations,
					);
				}

				// Возвращаем корректный запрос
				return {
					url: API_ENDPOINTS.PROJECT.CREATE(account_id),
					method: 'POST',
					body: serializedBody,
				};
			},
			invalidatesTags: [{ type: 'Projects', id: 'LIST' }],
		}),

		// Обновление проекта
		updateProject: builder.mutation<
			Project,
			{ account_id: string | number; id: string | number; body: Partial<Project> }
		>({
			query: (body) => {
				// Перечисляем нужные поля, которые хотим отправить
				const includeFields = [
					'account_id',
					'name',
					'status',
					// 'connected_ai_prompts',
					// 'connected_integrations',
					'id',
				];
				const selectedBody = selectFields(body, includeFields);

				const { account_id, id, ...serializedBody } = selectedBody;

				if (serializedBody.connected_ai_prompts) {
					serializedBody.connected_ai_prompts = processField(
						serializedBody.connected_ai_prompts,
					);
				}

				if (serializedBody.connected_integrations) {
					serializedBody.connected_integrations = processField(
						serializedBody.connected_integrations,
					);
				}

				// Возвращаем корректный запрос
				return {
					url: API_ENDPOINTS.PROJECT.UPDATE(account_id, id),
					method: 'PUT',
					body: serializedBody,
				};
			},

			invalidatesTags: (_result, _error, { id }) => [
				{ type: 'Projects', id: id },
				{ type: 'Projects', id: 'LIST' },
			],
		}),

		// Удаление проекта
		deleteProject: builder.mutation<void, { account_id: string | number; id: string | number }>(
			{
				query: ({ account_id, id }) => {
					// Формируем запрос для удаления проекта
					return {
						url: API_ENDPOINTS.PROJECT.DELETE(account_id, id),
						method: 'DELETE',
					};
				},
				invalidatesTags: (_result, _error, { id }) => [
					{ type: 'Projects', id: id },
					{ type: 'Projects', id: 'LIST' },
				],
			},
		),

		addIntegrationToProject: builder.mutation<
			IntegrationResponse, // Определите соответствующий тип ответа
			{ account_id: string | number; project_id: string | number; body: IntegrationRequest }
		>({
			query: ({ account_id, project_id, body }) => ({
				url: API_ENDPOINTS.PROJECT.ADD_INTEGRATION(account_id, project_id),
				method: 'POST',
				body,
			}),
			invalidatesTags: (_result, _error, { project_id }) => [
				{ type: 'Projects', id: project_id },
				{ type: 'ProjectIntegrations', id: 'LIST' },
			],
		}),

		// Обновление интеграции в проекте
		updateIntegrationInProject: builder.mutation<
			IntegrationResponse, // Определите соответствующий тип ответа
			{
				account_id: string | number;
				project_id: string | number;
				integration_project_id: string | number;
				body: IntegrationRequest;
			}
		>({
			query: ({ account_id, project_id, integration_project_id, body }) => ({
				url: API_ENDPOINTS.PROJECT.UPDATE_INTEGRATION(
					account_id,
					project_id,
					integration_project_id,
				),
				method: 'PUT',
				body,
			}),
			invalidatesTags: (_result, _error, { project_id, integration_project_id }) => [
				{ type: 'Projects', id: project_id },
				{ type: 'ProjectIntegrations', id: integration_project_id },
			],
		}),

		// Удаление интеграции из проекта
		deleteIntegrationFromProject: builder.mutation<
			void,
			{
				account_id: string | number;
				project_id: string | number;
				integration_project_id: string | number;
			}
		>({
			query: ({ account_id, project_id, integration_project_id }) => ({
				url: API_ENDPOINTS.PROJECT.DELETE_INTEGRATION(
					account_id,
					project_id,
					integration_project_id,
				),
				method: 'DELETE',
			}),
			invalidatesTags: (_result, _error, { project_id, integration_project_id }) => [
				{ type: 'Projects', id: project_id },
				{ type: 'ProjectIntegrations', id: integration_project_id },
			],
		}),

		// Добавление промпта к проекту
		addAiPromptToProject: builder.mutation<
			AiPromptResponse,
			{ account_id: string | number; project_id: string | number; body: AiPromptRequest }
		>({
			query: ({ account_id, project_id, body }) => {
				const updatedBody = { ...body };

				if (updatedBody.settings) {
					updatedBody.settings = processField(updatedBody.settings);
				}

				return {
					url: API_ENDPOINTS.PROJECT.ADD_AI_PROMPT(account_id, project_id),
					method: 'POST',
					body: updatedBody,
				};
			},
			invalidatesTags: (_result, _error, { project_id }) => [
				{ type: 'Projects', id: project_id },
				{ type: 'ProjectAiPrompts', id: 'LIST' },
			],
		}),

		// Обновление промпта в проекте
		updateAiPromptInProject: builder.mutation<
			AiPromptResponse,
			{
				account_id: string | number;
				project_id: string | number;
				ai_prompt_id: string | number;
				body: AiPromptRequest;
			}
		>({
			query: ({ account_id, project_id, ai_prompt_id, body }) => {
				// Создаём копию body перед изменением
				const updatedBody = { ...body };

				if (updatedBody.settings) {
					updatedBody.settings = processField(updatedBody.settings);
				}

				return {
					url: API_ENDPOINTS.PROJECT.UPDATE_AI_PROMPT(
						account_id,
						project_id,
						ai_prompt_id,
					),
					method: 'PUT',
					body: updatedBody,
				};
			},
			invalidatesTags: (_result, _error, { ai_prompt_id }) => [
				{ type: 'ProjectAiPrompts', id: ai_prompt_id },
			],
		}),

		// Удаление промпта из проекта
		deleteAiPromptFromProject: builder.mutation<
			void,
			{
				account_id: string | number;
				project_id: string | number;
				ai_prompt_id: string | number;
			}
		>({
			query: ({ account_id, project_id, ai_prompt_id }) => {
				return {
					url: API_ENDPOINTS.PROJECT.DELETE_AI_PROMPT(
						account_id,
						project_id,
						ai_prompt_id,
					),
					method: 'DELETE',
				};
			},
			invalidatesTags: (_result, _error, { project_id, ai_prompt_id }) => [
				{ type: 'Projects', id: project_id },
				{ type: 'ProjectAiPrompts', id: ai_prompt_id },
			],
		}),
	}),
});

export const {
	useGetProjectsQuery,
	useGetProjectsMetaQuery,
	useGetProjectByIdQuery,
	useCreateProjectMutation,
	useUpdateProjectMutation,
	useDeleteProjectMutation,
	useAddIntegrationToProjectMutation,
	useUpdateIntegrationInProjectMutation,
	useDeleteIntegrationFromProjectMutation,
	useAddAiPromptToProjectMutation,
	useUpdateAiPromptInProjectMutation,
	useDeleteAiPromptFromProjectMutation,
} = projectsApi;
