import { useAppSelector } from '@/app/store/hooks';
import { User } from '@/modules/auth/types';

interface useAuthStateProps {
	user: User | null;
	token: string | null;
	isAuthenticated: boolean;
}

const useAuthState = (): useAuthStateProps => {
	const { user, token } = useAppSelector((state) => state.auth);
	const isAuthenticated = Boolean(token);

	return { user, token, isAuthenticated };
};

export default useAuthState;
