export const buildQueryParams = <T extends Record<string, any>>(
	filters: Partial<T>,
	filterRules: Record<string, (filters: Partial<T>, params: URLSearchParams) => void>,
) => {
	const params = new URLSearchParams();

	Object.keys(filterRules).forEach((key) => {
		if (filters && filterRules[key]) {
			filterRules[key](filters, params);
		}
	});

	return params.toString() ? `?${params.toString()}` : '';
};
