import { StrictMode, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './index.scss';
import {
	createRoutesFromChildren,
	matchRoutes,
	useLocation,
	useNavigationType,
} from 'react-router-dom';
import * as Sentry from '@sentry/react';

// Инициализируем Sentry только если находимся в продакшн-среде
if (import.meta.env.MODE === 'production') {
	Sentry.init({
		dsn: 'https://08f3d07c9d47471c8cb259ad169acd61@sentry.aihub.ws/4',
		integrations: [
			Sentry.reactRouterV6BrowserTracingIntegration({
				useEffect: useEffect,
				useLocation,
				useNavigationType,
				createRoutesFromChildren,
				matchRoutes,
			}),
		],
		tracesSampleRate: 1.0,
	});
}

const rootElement = document.getElementById('root');

if (rootElement) {
	createRoot(rootElement).render(
		<StrictMode>
			<App />
		</StrictMode>,
	);
} else {
	console.error('Root element not found. Проверьте структуру HTML.');
}
